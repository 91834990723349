import { $http } from '~/boot/plugins/interceptors'
import type {
  AuthUser,
  LoginForm,
  LoginResponse,
  TwoFaPayload
} from '~/ts/interfaces/services/login.interface'
import type { User } from '~/ts/interfaces/models/user.model.interface'
import type {
  ForgotPasswordForm,
  ForgotPasswordResponse
} from '~/ts/interfaces/services/forgotPassword.interface'
import type {
  ResetPasswordPayload,
  ResetPasswordResponse
} from '~/ts/interfaces/services/resetPassword.interface'

export default {
  async login(payload: LoginForm): Promise<LoginResponse> {
    const { data } = await $http.post<LoginResponse>(`/login`, payload)
    return data
  },

  async twoFA(payload: TwoFaPayload): Promise<AuthUser> {
    const { data } = await $http.post<AuthUser>(`/google2fa`, payload)
    return data
  },

  async authUser(params = {}): Promise<User> {
    const { data } = await $http.get<User>(`/auth-user`, params)
    return data
  },

  async forgotPassword(payload: ForgotPasswordForm): Promise<ForgotPasswordResponse> {
    const { data } = await $http.post<ForgotPasswordResponse>(`/reset-password-link`, payload)
    return data
  },

  async resetPassword(payload: ResetPasswordPayload): Promise<ResetPasswordResponse> {
    const { data } = await $http.post<ResetPasswordResponse>(`/reset-password`, payload)
    return data
  },

  async logout() {
    const { data } = await $http.post(`/logout`)
    return data
  }
}

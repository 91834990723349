<script setup lang="ts">
import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'
import { definePage } from 'vue-router/auto'
import ThemeToggle from '~/components/shared/ThemeToggle.vue'

definePage({
  meta: {
    requiresAuth: false
  }
})

defineComponent({
  name: 'AuthLayout'
})
</script>

<template>
  <div class="container h-screen flex-col items-center justify-center">
    <div class="relative">
      <div class="absolute right-5 top-5">
        <ThemeToggle />
      </div>
    </div>
    <RouterView />
  </div>
</template>

<route lang="json">
{
  "meta": {
    "requiresAuth": false
  }
}
</route>

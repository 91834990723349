export enum SEARCH_TYPE {
  INTELLIGENT = 'intelligent',
  ADVANCED = 'advanced'
}

export enum OptionType {
  WAZO = 'wazo',
  VANGNET = 'vangnet',
  OTHER = 'other'
}
export enum GENDER {
  MALE = 'M',
  FEMALE = 'F',
  UNKNOWN = 'U'
}

export enum RoomStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

export enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset'
}

export enum AuthorizationLevels {
  System = 'system',
  Client = 'client',
  Employer = 'employer',
  Division = 'division',
  Department = 'department',
  Person = 'person'
}

export enum ActionButtonType {
  LINK = 'link',
  MODAL = 'modal',
  CONFIRM = 'confirm',
  CONDITION = 'condition',
  VIDEO = 'video'
}

export enum NOTIFICATION_TYPE {
  NOTIFICATION = 'notification',
  ALERT = 'alert'
}

export enum ActionListTypes {
  Contractor = 'contractor',
  Employer = 'employer',
  Division = 'division',
  Department = 'department',
  Person = 'person'
}

export enum TASK_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  HALT = 'halt',
  NO_SHOW = 'no_show'
}

export enum ACTION_LIST_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  NO_SHOW = 'no_show',
  DELETED = 'deleted',
  EXECUTED = 'executed',
  INVOICED = 'invoiced',
  PAID = 'paid'
}

export enum TemplateType {
  SURVEY = 'survey',
  FORM = 'form',
  EMAIL = 'email',
  SMS = 'sms'
}

export enum USER_MODELS {
  EMPLOYER = 'App\\Models\\Users\\EmployerUser',
  PROFESSIONAL = 'App\\Models\\Users\\Professional',
  PERSON = 'App\\Models\\Users\\FileUser',
  ADMIN = 'App\\Models\\Users\\AdminUser'
}

export enum OnlineStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away'
}

export enum LanguageEnum {
  NL = 'nl',
  EN = 'en'
}

export enum UserType {
  ADMIN = 'admin',
  PROFESSIONAL = 'professional',
  EMPLOYER = 'employer',
  PERSON = 'person'
}

export enum EMPLOYER_USER_ROLES {
  SUPERVISOR = 'supervisor',
  HSE_CONTACT_PERSONS = 'hse contact person',
  ABSENCE_CONTACT_PERSONS = 'absence contact person'
}

export enum ChatMessageType {
  Text = 'text',
  Notification = 'notification'
}

export enum ChartTypeEnum {
  FREQUENCY = 'Frequency',
  PERCENTAGE = 'Percentage'
}
export enum TimeFrame {
  YEAR = 'Year',
  MONTH = 'Month',
  THREE_MONTHS = '3-Months'
}

export enum EntityType {
  Client = 'client',
  Employer = 'employer',
  Division = 'division',
  Department = 'department'
}

export enum AppointmentType {
  ON_SITE = 'on_site',
  VIDEO_CALL = 'video_call',
  PHONE_CALL = 'phone_call'
}

export enum ActivityType {
  PERSON = 'person',
  ORGANISATION = 'organisation'
}

export enum ActivityActionListStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  NO_SHOW = 'no_show',
  DELETED = 'deleted',
  EXECUTED = 'executed',
  INVOICED = 'invoiced',
  PAID = 'paid'
}

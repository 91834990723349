<script setup lang="ts">
import Button from '~/components/ui/button/Button.vue'
import ThemeToggle from '~/components/shared/ThemeToggle.vue'
import { definePage } from 'vue-router/auto'

definePage({
  name: '404'
})
</script>

<template>
  <div class="container mx-auto">
    <div class="relative">
      <div class="absolute right-5 top-5">
        <ThemeToggle />
      </div>
    </div>
    <div class="h-screen flex flex-col justify-center items-center">
      <h1 class="text-5xl font-semibold">404</h1>
      <p class="text-xl my-3">
        {{ $t('Oops! The page you are looking for does not exist.') }}
      </p>
      <router-link to="/">
        <Button>
          {{ $t('Go to Home') }}
        </Button>
      </router-link>
    </div>
  </div>
</template>

<route lang="json">
{
  "name": "404",
  "meta": {
    "requiresAuth": false
  }
}
</route>

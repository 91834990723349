import { defineStore } from 'pinia'
import AuthApi from '~/api/AuthApi'
import type { User } from '~/ts/interfaces/models/user.model.interface'
import type { AuthState, AuthUser } from '~/ts/interfaces/services/login.interface'
import { getAuthCookie, removeAuthCookie, setAuthCookie } from '~/utils/auth-cookie'

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    authUser: null,
    bearerToken: getAuthCookie(),
    isLoggedIn: !!getAuthCookie(),
    kickOut: false
  }),

  actions: {
    async fetchAuthUser(): Promise<void> {
      await AuthApi.authUser()
        .then((response: User) => {
          this.authUser = response
          this.setLocale(response.setting?.settings.locale || 'en')
        })
        .catch(() => {
          this.resetAuthUser()
        })
    },

    setAuthUser(data: AuthUser): void {
      setAuthCookie(data.token)
      this.bearerToken = data.token
      this.isLoggedIn = !!data.token
      this.authUser = data.user
      this.setLocale(data.user.setting?.settings.locale || 'en')
    },

    setLocale(locale: string): void {
      localStorage.setItem('locale', locale)
    },

    resetAuthUser(): void {
      this.authUser = null
      this.bearerToken = null
      this.isLoggedIn = false
      removeAuthCookie()

      this.kickOut = true
    }
  }
})
